import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/guide_items"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.guide_items
  },
  async get(itemID) {
    let response = await Repository.get(`${resource}/${itemID}`)
    return response.data.guide_item
  },
  async create(itemData, addCollection = false) {
    const formData = new FormData()
    const entries = Object.entries(itemData)
    for (const [key, value] of entries) {
      formData.append(
        `guide_item[${key}]`,
        typeof value === "undefined" || value === null ? "" : value
      )
    }
    formData.append("region_id", store.state.auth.chosenRegion)

    if (addCollection) {
      formData.append("add_collection", addCollection)
    }

    let response = await Repository.post(`${resource}`, formData)
    return response.data
  },
  async update(itemID, itemData) {
    const formData = new FormData()
    const entries = Object.entries(itemData)
    for (const [key, value] of entries) {
      formData.append(
        `guide_item[${key}]`,
        typeof value === "undefined" || value === null ? "" : value
      )
    }

    let response = await Repository.patch(`${resource}/${itemID}`, formData)
    return response.data
  },
  async destroy(itemID) {
    let response = await Repository.delete(`${resource}/${itemID}`)
    return response.data
  },
  async sort(items) {
    let response = await Repository.patch(`${resource}/sort`, {
      guide_items: items
    })
    return response.data
  },
  async clone(itemId, regionId) {
    let response = await Repository.patch(`${resource}/clone`, {
      id: itemId,
      region_id: regionId
    })
    return response.data
  }
}
