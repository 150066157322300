<template>
  <base-form
    v-slot="slotProps"
    :model="item"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Element"
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Untertitel" prop="subtitle">
      <el-input
        v-model="slotProps.model.subtitle"
        :maxlength="120"
        show-word-limit
      ></el-input>
    </el-form-item>

    <image-upload-field
      current-label="Aktuelles Bild"
      label="Bild auswählen"
      :current-image="slotProps.model.image_url"
      @file-chosen="setFile"
    />

    <el-form-item label="Typ">
      <el-switch
        v-model="slotProps.model.is_link"
        active-text="Verlinkung"
        inactive-text="Ordner"
      ></el-switch>
    </el-form-item>

    <el-form-item
      v-if="slotProps.model.is_link"
      label="URL der Verlinkung"
      prop="url"
    >
      <el-input v-model="slotProps.model.url"></el-input>
    </el-form-item>

    <el-form-item
      v-if="mode === 'edit' && item.type === 'institutions'"
      label="Standardsortierung der Einrichtungen"
      prop="institutions_default_sort"
    >
      <el-radio-group v-model="slotProps.model.institutions_default_sort">
        <el-radio label="name">Name</el-radio>
        <el-radio label="city">Ort</el-radio>
        <el-radio label="zipcode">Postleitzahl</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="Status">
      <el-switch
        v-model="slotProps.model.published"
        active-text="Veröffentlicht"
        inactive-text="Entwurf"
      ></el-switch>
    </el-form-item>
  </base-form>
</template>

<script>
import BaseForm from "@/components/forms/BaseForm"
import ImageUploadField from "@/components/ImageUploadField"

export default {
  components: {
    BaseForm,
    ImageUploadField
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    rules() {
      return {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        url: [
          {
            required: this.item.is_link === true,
            message: "Bitte URL für die Verlinkung angeben",
            trigger: "blur"
          },
          { validator: this.validateUrl, trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    setFile(file) {
      this.item.image = file
    },
    validateUrl(rule, value, callback) {
      if (
        value &&
        (value.includes("https://") ||
          value.includes("http://") ||
          value.startsWith("/"))
      ) {
        callback()
      } else {
        callback(
          new Error(
            "Die URL muss für externe Verlinkungen mit 'http://' oder 'https://' beginnen oder für interne Verlinkungen innerhalb der App mit einem '/' beginnen (z.B. '/downloads')."
          )
        )
      }
    }
  }
}
</script>
