<template>
  <div>
    <the-breadcrumbs
      :custom-breadcrumbs="isTopLevel ? [] : breadcrumbs"
    ></the-breadcrumbs>
    <page-content :page-title="pageTitle">
      <guide-item-form
        :item="item"
        submit-label="Neues Element anlegen"
        :form-submitted="saveItem"
        mode="new"
      ></guide-item-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import GuideItemForm from "@/components/forms/GuideItemForm"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"

export default {
  metaInfo: {
    title: "Neues Element"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    GuideItemForm
  },
  data() {
    return {
      loading: false,
      item: {
        published: true,
        is_link: false
      },
      addCollection: false,
      isTopLevel: true
    }
  },
  computed: {
    pageTitle() {
      if (this.$route.query.addCollection == "articles") {
        return "Neue Artikelsammlung"
      } else if (this.$route.query.addCollection == "institutions") {
        return "Neue Einrichtungssammlung"
      } else if (this.item.is_link) {
        return "Neue Verlinkung"
      } else {
        return "Neuer Ordner"
      }
    },
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        },
        {
          path: `/guide_items/${this.item.guide_item_id}`,
          text: `${this.$route.query.parentName}`
        }
      ]
    }
  },
  async created() {
    if (this.$route.query.guide_item_id) {
      this.isTopLevel = false
      this.item.guide_item_id = this.$route.query.guide_item_id
    }
    if (this.$route.query.addCollection) {
      this.addCollection = this.$route.query.addCollection
    }
    if (this.$route.query.is_link) {
      this.item.is_link = true
    }
  },
  methods: {
    async saveItem(item) {
      await GuideItemsRepository.create(item, this.addCollection)
      if (this.item.guide_item_id) {
        this.$router.push({
          name: "ShowGuideItemPage",
          params: { id: this.item.guide_item_id }
        })
      } else {
        this.$router.push({ name: "GuidePage" })
      }
    }
  }
}
</script>
